import React, { useEffect } from "react";

const NotFound = () => {
  const seo = {metaDesc: '404 - Page Not Found'}
  useEffect(() => {
    if (window) {
      window.location.href = '/';
    }
  }, []);
  return (
    <></>
  )
}

export default NotFound